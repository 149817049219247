import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import QRCode from "react-qr-code";


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  
  @media(max-width: 320px) {
    display: block;
    padding: 0 10px;
  }
`;

const Name = styled.div`
  margin-top: 30px;
  font-size: 50px;
  font-weight: 00;
`;

const Title = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 20px;
`;

const PillarLink = styled.a`
  color: #7501d9; 

  &:visited {
    color: #7501d9;
  }
`;

const Logo = styled.img`
  margin-top: 50px;
  width: 250px;
  
  @media(max-width: 250px) {
    width: 100%;
  }
`;

const StyledQRCode = styled(QRCode)`
  margin: 30px 0;
`;

const Button = styled.a`
  display: block;
  padding: 20px 30px;
  text-align: left;
  background: #7501d9;
  color: #fff;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &:visited {
    color: #fff;
    text-decoration: none;
  }
  
  span {
    margin-left: 10px;
  }

  width: 250px;

  @media(max-width: 320px) {
    width: auto;
  }
`;

const contacts = {
  default: {
    name: 'Pillar.fi',
  },
  michael: {
    name: 'Michael Messele',
    title: 'CEO',
    email: 'michael@pillar.fi',
    phone: '+44 7949 499329',
    telegram: '@mmm319',
    twitter: '@mm319',
    shortLink: 'https://bit.ly/3zSWccp',
    linkedIn: 'michaelmessele',
  },
  jack: {
    name: 'Jack Bass',
    title: 'CMO',
    email: 'jack@pillar.fi',
    telegram: '@pillar_jack',
    shortLink: 'https://bit.ly/39BtVg1',
  },
  kieran: {
    name: 'Kieran Goodary',
    title: 'Head of Mobile Engineering',
    email: 'kieran@pillar.fi',
    phone: '+44 2039 742181',
    telegram: '@itsKieran',
    twitter: '@KioG',
    shortLink: 'https://bit.ly/3N7AHrd',
    linkedIn: 'kierangoodary',
  },
  deimantas: {
    name: 'Deimantas Spucys',
    title: 'Senior Software Developer',
    email: 'deimantas@pillar.fi',
    telegram: '@poocart',
    twitter: '@poocart',
    shortLink: 'https://bit.ly/3OrZgQT',
  },
  aldin: {
    name: 'Aldin Ademović',
    title: 'CPO',
    email: 'aldin@pillar.fi',
    phone: '+44 7788 246387',
    shortLink: 'https://bit.ly/3NacccU',
  },
  marisa: {
    name: 'Marisa Gisbert',
    title: 'Finance Manager',
    email: 'marisa@pillar.fi',
    phone: '+44 7375510516',
    linkedIn: 'marisa-gisbert-cruz-finance-and-operations-manager',
    shortLink: 'https://bit.ly/3bgLKkR',
  },
  partha: {
    name: 'Parthasarathy Ramanujam',
    title: 'CTO',
    email: 'partha@etherspot.io',
    linkedIn: 'parthasarathy-ramanujam',
    shortLink: 'https://bit.ly/3HDLKHN',
  },
  alexandra: {
    name: 'Alexandra Nartova',
    title: 'Head of Marketing',
    email: 'alexandra@pillar.fi',
    linkedIn: 'alexandra-nartova',
    shortLink: 'https://bit.ly/3N7FGsc',
  },
  kyle: {
    name: 'Kyle Young',
    title: 'Growth Lead',
    email: 'kyle@pillar.fi',
    linkedIn: 'kyle-s-young',
    shortLink: 'https://bit.ly/3xLZOKu',
  },
  vlada: {
    name: 'Vladyslava Kozlovska',
    title: 'Social Media Manager',
    email: 'vlada@pillar.fi',
    linkedIn: 'vladyslava-kozlovska',
    shortLink: 'https://bit.ly/3QOvwjl',
  },
};

const App = () => {
  const { contactId } = useParams();

  const contact = contacts?.[contactId ?? 'default'];

  if (!contact) return null;

  const {
    title,
    name,
    twitter,
    telegram,
    phone,
    email,
    linkedIn,
    shortLink,
  } = contact;

  return (
    <Wrapper>
      <Logo src="/pillar-logo-horizontal.png" alt="pillar-logo-horizontal" title="Pillar " />
      {name && <Name>{name}</Name>}
      {title && <Title>{title}, <PillarLink href="https://pillar.fi" title="Pillar" target="_blank">Pillar.fi</PillarLink></Title>}
      {email && <Button href={`mailto:${email}`} target="_blank">Email: <span>{email}</span></Button>}
      {twitter && <Button href={`https://twitter.com/${twitter.replaceAll('@', '')}`} target="_blank">Twitter: <span>{twitter}</span></Button>}
      {telegram && <Button href={`https://t.me/${telegram.replaceAll('@', '')}`} target="_blank">Telegram: <span>{telegram}</span></Button>}
      {linkedIn && <Button href={`https://www.linkedin.com/in/${linkedIn}`} target="_blank">LinkedIn: <span>{linkedIn}</span></Button>}
      {phone && <Button href={`tel:${phone.replaceAll(' ', '')}`} target="_blank">Phone: <span>{phone}</span></Button>}
      <StyledQRCode size={220} value={shortLink} />
    </Wrapper>
  );
};

export default App;
